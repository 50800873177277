<template>
  <div class="detail positionr">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/paramSetting' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <el-col :span="24">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('formData')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :rules="rules"
                  :model="formData"
                  ref="formData"
                  label-width="150px"
                  class="formData"
                  size="mini"
                >
                  <!-- <el-row> -->
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item
                      label="所属电子围栏"
                      prop="electronicFenceCode"
                    >
                      <!-- <el-input
                          v-model="formData.electronicFenceName"
                          disabled
                        ></el-input> -->
                      <el-select
                        v-model="formData.electronicFenceCode"
                        placeholder="请选择"
                        @change="change"
                        style="width:100%;"
                      >
                        <el-option
                          v-for="item in electronicFenceList"
                          :key="item.id"
                          :label="item.electronicFenceName"
                          :value="item.electronicFenceCode"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="所属运营商" prop="operatorName">
                      <el-select
                        v-model="formData.operatorName"
                        placeholder="请选择运营商"
                        @change="selectOperator"
                        clearable
                        value-key="operatorName"
                        style="width:100%;"
                      >
                        <el-option
                          v-for="item in operatorList"
                          :key="item.operatorCode"
                          :label="item.operatorName"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="所属站长" prop="administratorName">
                      <el-input
                        v-model="formData.administratorName"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-col>

                  <!-- <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item
                        label="运营商登录密码"
                        prop="operatorPassword"
                      >
                        <el-input
                          type="password"
                          v-model="formData.operatorPassword"
                          show-password
                          :maxlength="18"
                          placeholder="请输入密码"
                        ></el-input>
                      </el-form-item>
                    </el-col> -->
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="锁编号" prop="deviceCode">
                      <el-input
                        v-model="formData.deviceCode"
                        :disabled="this.id != ''"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="车辆编号" prop="deviceNum">
                      <el-input
                        v-model="formData.deviceNum"
                        :disabled="this.id != ''"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="经度" prop="latitude">
                      <el-input v-model="formData.latitude" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="纬度" prop="longitude">
                      <el-input
                        v-model="formData.longitude"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- </el-row>
                  <el-row> -->
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="主电量" prop="electricQuantity">
                      <el-input v-model="formData.electricQuantity"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="设备状态" prop="deviceStatus">
                      <el-select v-model="formData.deviceStatus">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <!-- </el-row>
                  <el-row> -->
                  <el-col :sm="24" :md="24" :lg="10">
                    <el-form-item label="二维码" prop="incomeRatio">
                      <el-image
                        style="height:200px;width:200px"
                        fit="fill"
                        :src="formData.qrCode"
                        :preview-src-list="qrCode"
                      ></el-image>
                      <el-input
                        v-model="formData.qrCode"
                        style="display:none"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- </el-row> -->
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      electronicFenceList: [],
      options: [
        {
          name: "上锁中",
          value: "1"
        },
        {
          name: "已开锁",
          value: "0"
        }
      ],
      rules: {
        electronicFenceCode: [
          { required: true, message: "所属电子围栏不能为空", trigger: "blur" }
        ],
        operatorName: [
          { required: true, message: "所属运营商不能为空", trigger: "blur" }
        ],
        administratorName: [
          { required: true, message: "所属管理员不能为空", trigger: "blur" }
        ],
        electricQuantity: [
          { required: true, message: "主电量不能为空", trigger: "blur" }
        ],
        deviceNum: [
          { required: true, message: "车辆编号不能为空", trigger: "blur" }
        ],
        deviceCode: [
          { required: true, message: "锁编号不能为空", trigger: "blur" }
        ]
      },
      id: "",
      formData: {
        electronicFenceCode: "",
        operatorCode: "",
        operatorName: ""
      },
      qrCode: [],
      operatorList: []
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDevice();
      this.loadOperator();
    }
    this.getElectronicFenceList();
  },
  methods: {
    //查询运营商列表
    loadOperator() {
      this.$http.post("/userOperator/queryAll", {}).then(res => {
        if (res.data.code == 200) {
          this.operatorList = res.data.data;
        }
      });
    },
    selectOperator(item) {
      this.formData.operatorCode = item.operatorCode;
      this.formData.operatorName = item.operatorName;
    },
    change() {
      this.electronicFenceList.forEach(e => {
        if (e.electronicFenceCode == this.formData.electronicFenceCode) {
          this.formData.electronicFenceName = e.electronicFenceName;
          this.formData.administratorName = e.administratorName;
          this.formData.administratorCode = e.administratorCode;
          // this.formData.operatorName = e.operatorName
          // this.formData.operatorCode = e.operatorCode
        }
      });
    },
    //查询电子围栏
    getElectronicFenceList() {
      var that = this;
      const data = {};
      this.$http.post("/electronicFence/queryList", data).then(res => {
        if (res.data.code == 200) {
          that.electronicFenceList = res.data.data;
        }
      });
    },
    goIndex() {
      this.$router.go(-1);
    },
    getDevice() {
      this.$http
        .post("/device/queryOne", {
          id: this.id
        })
        .then(res => {
          console.log(res, "单个的");
          this.formData = res.data.data;
          this.qrCode.push(res.data.data.qrCode);
        });
    },
    submitForm() {
      this.$http.post("/device/save", this.formData).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          this.$notify.success({
            title: "消息",
            message: "修改成功",
            showClose: true
          });
        } else {
          this.$notify.success({
            title: "",
            message: res.data.message,
            showClose: true
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scope>
/deep/ .el-select {
  display: block !important;
}

.h50 {
  height: 50px;
}

.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }

  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;

      li {
        height: 50px;
        line-height: 50px;

        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }

      li.active,
      li:hover {
        background-color: #fff;
      }
    }

    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;

      .p1 {
        margin-bottom: 20px;
      }

      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;

          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }

          .el-input {
            width: 247px;
          }

          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }

      .header-input {
        font-size: 13px;
      }

      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }

        .el-table {
          margin-top: 10px;
        }
      }
    }
  }

  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }

  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }

  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
</style>
